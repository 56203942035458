// Load vendors
import axios from 'axios';
import store from '../redux/store';

// Load configs
import { HOST } from 'configs/host';
import { logout } from 'app/Auth/actions';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: {
    common: {
      platform: 'admin',
      // Fundraising: window.location.origin || 'https://www.pooldev.live',
      // Fundraising: 'https://www.pooldev.live',
    },
  },
});

export const ApiPublic = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PUBLIC_PREFIX}`,
});

export const setAuthToken = (authToken, api) => {
  api.defaults.headers.common[HOST.API.AUTH_HEADER] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};

const handleResponseInterceptor = response => response;

const handleRequestFailInterceptor = error => {
  if (error.response && error.response.status) {
    const { dispatch, getState } = store;
    const { isAuthenticated } = getState();
    const { status } = error.response;
    if (isAuthenticated && status === 401) {
      dispatch(logout());
    }
  }
  return Promise.reject(error);
};

// Response interceptor
Api.interceptors.response.use(handleResponseInterceptor, handleRequestFailInterceptor);
