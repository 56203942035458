import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import Select from 'react-select';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import { selectCustomStyles } from 'configs';

function capitalize(word) {
  if (!word) return '';
  const loweredCase = word.toLowerCase();
  return word[0].toUpperCase() + loweredCase.slice(1);
}

const PoolGeneralStep = ({ form, setForm, matchStarted }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newForm = { ...form };
    newForm[name] = value;
    setForm(newForm);
  };

  const handleCheckBoxChange = ({ target }) => {
    const { name, checked } = target;
    const newForm = { ...form };
    newForm.weekly_winner_settings[name] = checked ? 1 : 0;
    setForm(newForm);
  };

  return (
    <div className='px-2'>
      <h6 className='mb-3'>Fundraiser General Settings</h6>
      <div className='d-flex row'>
        <div className='col-12 user-creation-form'>
          <ul className='p-0 mb-0 col-5'>
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Fundraiser Name*:</label>
              </div>
              <EditingInput
                type='text'
                name='name'
                value={form.name}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={true}
                maxLength='180'
              />
            </li>
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Fundraiser Season*:</label>
              </div>
              <Select
                isDisabled={matchStarted}
                styles={selectCustomStyles}
                className='multi-select'
                classNamePrefix='multi-select-pre'
                getOptionLabel={o => o.id}
                getOptionValue={o => o.id}
                value={{ id: form.season }}
                onChange={season => setForm({ ...form, season: season.id })}
                options={[{ id: 2022 }, { id: 2023 }, { id: 2024 }]}
                required
              />
            </li>
          </ul>
        </div>
      </div>
      <div className='d-flex row mt-5'>
        <div className='col-6 user-creation-form pl-4'>
          <p className='font-weight-bold weight-500 text-semy-grey'>Weekly Winner Settings*</p>
          <div className='col-4 pl-0 mb-3'>
            <label className='m-0 text-black-50'>Calculation Method*</label>
          </div>
          <ul className='p-0 mb-0 col-6'>
            <li className='form-field-group is-checkbox'>
              <EditingCheckBox
                name='wins'
                value={form.weekly_winner_settings.wins}
                onChange={handleCheckBoxChange}
                className='p-0'
                checked={form.weekly_winner_settings.wins}
                editing={true}
                disabled={matchStarted}
              />
              <div className='col-4 pl-0'>
                <label className='m-0'>Wins</label>
              </div>
            </li>
            <li className='form-field-group is-checkbox'>
              <EditingCheckBox
                name='loss'
                value={form.weekly_winner_settings.loss}
                onChange={handleCheckBoxChange}
                className='p-0'
                checked={form.weekly_winner_settings.loss}
                editing={true}
                disabled={matchStarted}
              />
              <div className='col-4 pl-0'>
                <label className='m-0'>Losses</label>
              </div>
            </li>
            <li className='list-default'>
              <div className='form-field-group is-checkbox'>
                <EditingCheckBox
                  name='tie'
                  value={form.weekly_winner_settings.tie}
                  onChange={handleCheckBoxChange}
                  className='p-0'
                  checked={form.weekly_winner_settings.tie}
                  editing={true}
                  disabled={matchStarted}
                />
                <div className='col-4 pl-0'>
                  <label className='m-0'>Ties</label>
                </div>
              </div>
            </li>
          </ul>
          <p className='create-info-message'>
            The week winner is calculated based upon <br /> teams’ score and chosen calculation
            method.
          </p>
        </div>
        <div className='col-6 user-creation-form'>
          <p className='font-weight-bold weight-500 text-semy-grey'>Season Calculation Settings*</p>
          <ul className='p-0 mb-0 col-4'>
            <li className='form-field-group'>
              <div className='col-12 pl-0'>
                <label className='m-0 text-black-50'>Calculation Basis*</label>
              </div>
              <Select
                styles={selectCustomStyles}
                className='multi-select'
                classNamePrefix='multi-select-pre'
                getOptionLabel={o => capitalize(String(o.id))}
                getOptionValue={o => o.id}
                value={{ id: form.season_winner_settings }}
                onChange={sel => setForm({ ...form, season_winner_settings: sel.id })}
                isOptionDisabled={option => option.disabled}
                options={[{ id: 'point' }, { id: 'score', disabled: true }]}
                isDisabled={matchStarted}
              />
            </li>
          </ul>
          <p className='create-info-message'>
            Loss = 0 point <br /> Tie = 1 point <br /> Win = 2 points
          </p>
        </div>
      </div>
    </div>
  );
};

export default PoolGeneralStep;
