import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError, getGameState } from 'utils/appHelpers';
import { IconBlank, IconPrint } from 'shared/components/Icons';
import { allWeeks, weeks } from 'app/Main/configs';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import Loading from 'shared/components/Loading';
import TeamSelect from 'shared/components/TeamSelect';

const UserEntriesView = ({ match }) => {
  const account = useSelector(state => state.account);
  const allowSelectAll = new URL(window.location.href).searchParams.get('allow_select_all');
  const [printFetch, setPrintFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id, poolId } = match.params;
  const [poolData, setPoolData] = useState();
  const [data, setData] = useState();
  const [user, setUser] = useState();

  const getUserData = async () => {
    try {
      const { data } = await Api.get(
        `/users/${id}/details?pool_type=fundraising&pool_id=${poolId}`,
      );
      setUser(data.profile);
      setData(data.participants.find(i => i.pool_id === Number(poolId)));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getPoolData = async () => {
    try {
      const { data } = await Api.get(`/pool/${poolId}?type=fundraising`);
      setPoolData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSheetPrint = async () => {
    try {
      setPrintFetch(true);
      const { data } = await Api.get(`/users/${id}/print?pool_id=${poolId}`);
      window.open(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setPrintFetch(false);
    }
  };

  useEffect(() => {
    getUserData();
    getPoolData();
  }, []);

  if (!user || !data || !poolData) return <Loading className='has-header' />;

  const weekData = poolData.pool_config.allow_postseason ? allWeeks : weeks;

  return (
    <div>
      <Header poolId={poolId} user={user} />
      <div className='has-header'>
        <div className='sheet-view-head justify-content-end col-12 d-flex align-items-center mb-5 px-9'>
          <button className='view-btn bg-white' disabled={printFetch} onClick={onSheetPrint}>
            <IconPrint />
          </button>
        </div>
        <div className='col-12 px-9 pb-5'>
          <div className='player-survival-stats d-flex flex-column'>
            <div className='stats-header d-flex align-items-center justify-content-between'>
              <p className='mb-0'>
                {user.firstname} {user.lastname}
              </p>
              <p className='mb-0'>{data.pool_name}</p>
              <p className='mb-0'>ID: {data.pool_id}</p>
            </div>
            <div className='stats-body d-flex'>
              <div className='stats-colum'>
                <div className='colum-header row-item text-nowrap pr-5 d-flex align-items-center'>
                  Weeks
                </div>
                {weekData.map((item, i) => {
                  const isCurrentWeek = account.currentWeek === item.id;
                  return (
                    <div
                      className={`colum-item row-item text-nowrap pr-5 d-flex align-items-center ${
                        isCurrentWeek ? 'active' : ''
                      }`}
                      key={item.id}
                    >
                      {isCurrentWeek && <span>●</span>} {item.name}
                    </div>
                  );
                })}
              </div>
              {data.participants.map((item, i) => {
                return (
                  <div className='stats-colum has-right-border' key={i}>
                    <div className='colum-header'>
                      <div className='row-item'>{item.entrance_name || '-'}</div>
                      <div className='d-flex'>
                        <div className='row-item row-p-md row-l'>Chosen Team</div>
                        <div className='row-item row-p-md'>Status</div>
                        <div className='row-item row-p-md'>Score</div>
                      </div>
                    </div>
                    {weekData.map((week, i) => {
                      const isCurrentWeek = account.currentWeek === week.id;
                      const canSelectTeam =
                        allowSelectAll ||
                        (week.id >= account.currentWeek && !item.out && week.id <= 18);
                      const currentWeekData = item.week_data?.find(i => i.week === week.id);
                      const gameState = getGameState(currentWeekData);
                      return (
                        <div
                          className={`colum-item ${isCurrentWeek ? 'active' : ''}`}
                          key={week.id}
                        >
                          <div className='d-flex h-100'>
                            <div
                              className={`row-item row-p-md row-l ${canSelectTeam ? 'p-0' : ''}`}
                            >
                              {!canSelectTeam && currentWeekData?.FullName}
                              {canSelectTeam && (
                                <TeamSelect
                                  id={item.participant_id}
                                  week={week.id}
                                  poolId={poolId}
                                  currentWeekData={currentWeekData}
                                  onTeamSelectSuccess={getUserData}
                                />
                              )}
                            </div>
                            <div className={`row-item text-capitalize row-p-md is-${gameState}`}>
                              {gameState}
                            </div>
                            <div className='row-item row-p-md'>{currentWeekData?.score}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {data.participants.length < 4 && (
                <div className='align-items-center col d-flex h-auto justify-content-center'>
                  <div className='text-center'>
                    <IconBlank />
                    <p className='mt-3'>No Other Entries</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEntriesView;
