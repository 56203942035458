import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { selectCustomStyles } from 'configs';

const defaultUserSelect = [
  { name: 'Not Assigned', id: null, fontWeight: '600', color: '#202020' },
  // { name: 'New User', id: 0, fontWeight: '600', color: '#202020' },
];

const SheetsCreateModal = ({ close, pools, users, onSuccessCreate }) => {
  const [fetch, setFetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    pool_id: '',
    count: '',
    season: 2024,
    user_id: null,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newForm = { ...form };
    newForm[name] = value;
    setForm(newForm);
  };

  const onCreateSheet = async () => {
    try {
      setFetch(true);
      const { data } = await Api.post('/sheet/generate', form);
      if (onSuccessCreate) onSuccessCreate();
      setSuccess(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onDownloadSheet = () => {
    window.open(success);
    close();
  };

  const onSelectPool = pool => {
    setForm({ ...form, pool_id: pool.pool_id });
  };

  const onSelectUser = user => {
    setForm({ ...form, user_id: user ? user.id : null });
  };

  const btnDisabled = fetch || !form.pool_id || !form.count;

  const customStyles = {
    ...selectCustomStyles,
    option: (provided, state) => {
      return {
        ...provided,
        fontWeight: state.data.fontWeight,
      };
    },
  };

  useEffect(() => {
    if (pools?.length) {
      onSelectPool(pools[0]);
    } else {
      enqueueSnackbar('No sheet can be generated until fundraiser is configured.', {
        variant: 'warning',
      });
    }
  }, [pools]);

  const activePool = pools?.find(i => Number(i?.pool_id) === Number(form?.pool_id));

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Sheet Creation</h5>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        {!success && (
          <div className='col-12 user-creation-form'>
            <ul className='p-0 mb-0 col-12'>
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Fundraiser*:</label>
                </div>
                <Select
                  value={activePool}
                  styles={selectCustomStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  getOptionLabel={o => o.poolname || o.name}
                  getOptionValue={o => o.pool_id}
                  onChange={onSelectPool}
                  options={pools}
                />
              </li>
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Player:</label>
                </div>
                <Select
                  styles={customStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  getOptionLabel={o => o.name || `${o.firstname} ${o.lastname}`}
                  getOptionValue={o => o.id}
                  defaultValue={defaultUserSelect[0]}
                  onChange={onSelectUser}
                  options={[...defaultUserSelect, ...users]}
                />
              </li>
              <li className='form-field-group'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Number of Sheets:</label>
                </div>
                <EditingInput
                  type='number'
                  name='count'
                  value={form.count}
                  onChange={handleChange}
                  className='p-0'
                  placeholder='Enter Number'
                  required
                  editing={true}
                  maxLength='180'
                />
              </li>
            </ul>
          </div>
        )}
        {success && (
          <div className='text-center sheet-success h-100 justify-content-center d-flex flex-column'>
            <h4 className='text-primary mb-4'> Sheet(s) successfully created!</h4>
            <h4 className='text-gray mb-3'>You can download and print now</h4>
            <p className='text-gray fz-12'>
              Or you can download each sheet <br /> from Sheets or from User Profile pages anytime
              later
            </p>
          </div>
        )}
      </div>
      <div className='m-footer creation-buttons text-center'>
        {!success && (
          <button
            className={`btn ${!btnDisabled ? 'btn-next' : ''}`}
            disabled={btnDisabled}
            onClick={onCreateSheet}
          >
            Create
          </button>
        )}
        {success && (
          <button className='btn btn-next' onClick={onDownloadSheet}>
            Download
          </button>
        )}
      </div>
    </div>
  );
};

export default SheetsCreateModal;
