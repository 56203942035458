/* eslint-disable no-eval */
import React, { useState } from 'react';
import { IconCalc } from 'shared/components/Icons';
import { showUsd } from 'utils/appHelpers';
import AmountSwitcher from './components/AmountSwitcher';
import AmountToggle from './components/AmountToggle';
import ReactModal from 'react-modal';
import CalcModal from './components/CalcModal';

const WEEK_COUNT = 18;

const checkAndPluse = ({ amount, is_valid } = {}, forceAmount) => {
  return is_valid ? forceAmount || amount || 0 : 0;
};

const AmountLine = ({ amount, is_valid, forceAmount }) => {
  return is_valid ? <span className='line-2'>{showUsd(forceAmount || amount || 0)}</span> : null;
};

const PoolFinancialStep = ({ form, setForm, matchStarted }) => {
  const [modalState, setModalState] = useState(false);

  const onChangeForm = (name, amount) => {
    try {
      const newForm = { ...form };
      eval(`newForm.${name} = ${amount || null}`);
      setForm(newForm);
    } catch (err) {}
  };

  const updateFormFromModal = changes => {
    setForm({ ...form, ...changes });
  };

  const { season, weekly } = form.bucket_mgmt || {};
  const { amount, first_place, second_place } = weekly || {};

  const weekResult = (amount / WEEK_COUNT).toFixed();
  const weekTotal = (first_place + checkAndPluse(second_place)).toFixed();
  const weekMissing = weekTotal - weekResult;

  const seasonResult =
    +season.first_place +
    +checkAndPluse(season.second_place) +
    +checkAndPluse(season.third_place) +
    +checkAndPluse(season.last_place, form.sheet_price?.preseason);
  const seasonMissing = season.amount - seasonResult;

  return (
    <div className='px-2 financial-page'>
      <div className='d-flex header-block justify-content-between align-items-center mb-4'>
        <h6 className='color-blue mb-3'>Financial</h6>
        <p className='m-0 d-flex align-items-center'>
          Total Bucket: {showUsd(form.bucket_mgmt?.total)}
        </p>
        <button type='button' className='btn p-0' onClick={() => setModalState(true)}>
          <IconCalc />
        </button>
      </div>
      <div className='white-block-info d-flex'>
        <p className='mb-0'>Bucket Amount</p>
        <p className='mb-0'>Final Value</p>
      </div>
      <div className='white-block d-flex align-items-center'>
        <p className='m-0 block-title'>Fundraiser Goal</p>
        <AmountSwitcher
          total={form.bucket_mgmt?.total}
          value={form.bucket_mgmt?.administrative_amount}
          onValueChange={onChangeForm.bind(null, 'bucket_mgmt.administrative_amount')}
        />
      </div>
      <div className='white-block d-flex'>
        <p className='m-0 block-title'>Weekly</p>
        <div>
          <AmountSwitcher
            total={form.bucket_mgmt?.total}
            value={form.bucket_mgmt?.weekly?.amount}
            onValueChange={onChangeForm.bind(null, 'bucket_mgmt.weekly.amount')}
            className='mb-20'
          />
          <AmountToggle
            name='First place'
            className='pt-20 mb-20'
            value={form.bucket_mgmt?.weekly?.first_place}
            onValueChange={onChangeForm.bind(null, 'bucket_mgmt.weekly.first_place')}
            disabled={matchStarted}
          />
          <AmountToggle
            name='Second place'
            className='mb-20'
            value={form.bucket_mgmt?.weekly?.second_place.amount}
            onValueChange={onChangeForm.bind(null, 'bucket_mgmt.weekly.second_place.amount')}
            toggle={form.bucket_mgmt?.weekly.second_place?.is_valid}
            onToggleChange={onChangeForm.bind(null, 'bucket_mgmt.weekly.second_place.is_valid')}
            disabled={true}
          />
        </div>
        <div className='ml-5 d-flex flex-column justify-content-center'>
          <div className='amount-calc-devide'>
            <div className='top-part'>
              <span className='devide-action'>÷</span>
              <AmountLine amount={form.bucket_mgmt?.weekly?.amount} is_valid={true} />
              <span className='line-2'>{WEEK_COUNT}</span>
            </div>
            <span className='result-line' />
            <span className={`result d-flex align-items-center ${weekMissing ? 'wrong' : ''}`}>
              {showUsd(weekResult)}
              {!!weekMissing && (
                <span className='result-missing text-nowrap'>(Missing {showUsd(weekMissing)})</span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className='d-flex'>
        <div className='col-8 white-block d-flex'>
          <p className='m-0 block-title'>Season</p>
          <div>
            <AmountSwitcher
              total={form.bucket_mgmt?.total}
              value={form.bucket_mgmt?.season.amount}
              onValueChange={onChangeForm.bind(null, 'bucket_mgmt.season.amount')}
              className='mb-4'
            />
            <AmountToggle
              name='First place'
              className='pt-20 mb-20'
              value={form.bucket_mgmt?.season.first_place}
              onValueChange={onChangeForm.bind(null, 'bucket_mgmt.season.first_place')}
            />
            <AmountToggle
              name='Second place'
              className='mb-20'
              value={form.bucket_mgmt?.season.second_place.amount}
              onValueChange={onChangeForm.bind(null, 'bucket_mgmt.season.second_place.amount')}
              toggle={form.bucket_mgmt?.season.second_place.is_valid}
              onToggleChange={onChangeForm.bind(null, 'bucket_mgmt.season.second_place.is_valid')}
              disabled={true}
            />
            <AmountToggle
              name='Third place'
              className='mb-20'
              value={form.bucket_mgmt?.season.third_place.amount}
              onValueChange={onChangeForm.bind(null, 'bucket_mgmt.season.third_place.amount')}
              toggle={form.bucket_mgmt?.season.third_place.is_valid}
              onToggleChange={onChangeForm.bind(null, 'bucket_mgmt.season.third_place.is_valid')}
              disabled={true}
            />
            <AmountToggle
              name='Last place'
              className='mb-20'
              value={0}
              toggle={form.bucket_mgmt?.season.last_place.is_valid}
              onToggleChange={onChangeForm.bind(null, 'bucket_mgmt.season.last_place.is_valid')}
              disabled={true}
            />
          </div>
          <div className='ml-5 d-flex flex-column justify-content-center'>
            <div className='amount-calc-devide'>
              <div className='top-part'>
                <span className='devide-action'>+</span>
                <AmountLine amount={season.first_place} is_valid={true} />
                <AmountLine {...season.second_place} />
                <AmountLine {...season.third_place} />
                <AmountLine {...season.last_place} forceAmount={form.sheet_price.preseason} />
              </div>
              <span className='result-line' />
              <span className={`result d-flex align-items-center ${seasonMissing ? 'wrong' : ''}`}>
                {showUsd(seasonResult)}
                {!!seasonMissing && (
                  <span className='result-missing text-nowrap ml-1'>
                    (Missing {showUsd(seasonMissing)})
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className='white-block col ml-2'>
          <p className='m-0 block-title'>Sheet price</p>
          <AmountToggle
            name='Preseason'
            className='pt-20 mb-20'
            value={form.sheet_price.preseason}
            onValueChange={onChangeForm.bind(null, 'sheet_price.preseason')}
            noCheckArea
            disabled={matchStarted}
          />
          <AmountToggle
            name='In-season'
            className='mb-20'
            value={form.sheet_price.inseason}
            onValueChange={onChangeForm.bind(null, 'sheet_price.inseason')}
            noCheckArea
            disabled={matchStarted}
          />
        </div>
      </div>
      <ReactModal isOpen={modalState} className='custom-modal'>
        <CalcModal
          matchStarted={matchStarted}
          initialForm={form}
          updateInitialForm={updateFormFromModal}
          close={() => setModalState(false)}
        />
      </ReactModal>
    </div>
  );
};

export default PoolFinancialStep;
