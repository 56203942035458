import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { selectCustomStyles } from 'configs';

const Users = () => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [pools, setPools] = useState([]);

  const [order, setOrder] = useState({ order: false, orderKey: 'firstname' });
  const [page, setPage] = useState(0);
  const [poolId, setPoolId] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getParams = noPage => {
    const params = { ...order, page: page + 1, limit: 10, pool_id: poolId };
    if (debouncedSearch) params.search = debouncedSearch;
    if (noPage) {
      delete params.page;
      delete params.limit;
    }
    return params;
  };

  const getData = useCallback(async () => {
    try {
      setFetch(true);
      const params = getParams();
      const { data } = await Api.get(`/users/list`, { params });
      setPageCount(Math.ceil(data.count / 10));
      setData(data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, order, page, poolId]);

  const getPools = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      // const qp3Data = await Api.get(`/pool/list?type=qp3`, { params });
      const survivalData = await Api.get(`/pool/list?type=fundraising`, { params });
      // const qp3Pools = qp3Data.data.results.map(i => ({ ...i, type: 'qp3' }));
      const survivalPools = survivalData.data.results.map(i => ({ ...i, type: 'fundraising' }));
      setPools([...survivalPools]);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onSelectPool = option => {
    setPoolId(option ? option.pool_id : null);
  };

  useEffect(() => {
    getData();
  }, [getData, page, order, poolId]);

  useEffect(() => {
    getPools();
  }, []);

  return (
    <div>
      <Header onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='form-field-group col-3 mb-4 d-none'>
          <Select
            styles={selectCustomStyles}
            className='multi-select'
            classNamePrefix='multi-select-pre'
            getOptionLabel={o => o.poolname || o.name}
            getOptionValue={o => o.pool_id}
            placeholder={'Select Fundraiser'}
            onChange={onSelectPool}
            options={pools}
            isClearable
          />
        </div>
        <div className='col-12'>
          <Lists
            list={data}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            getParams={getParams}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft color='#C2C2C2' />}
            nextLabel={<IconArrowRight color='#C2C2C2' />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
