import React from 'react';
// import { Link } from 'react-router-dom';

const Header = ({ name }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        {/* <Link to='/fundraiser'>All Fundraiser</Link>
        <span className='mx-2'>/</span> */}
        <span>{name}</span>
      </div>
    </div>
  );
};

export default Header;
