import React, { useState } from 'react';
import { IconClose } from 'shared/components/Icons';
import { showUsd } from 'utils/appHelpers';
import AmountSwitcher from './AmountSwitcher';
import AmountToggle from './AmountToggle';

const TOTAL_WEEKS = 18;

const MathCalcForm = ({ symbol, numbers, isPlus }) => {
  const add = (ac, a) => ac + Number(a);
  const sum = numbers.reduce(add, 0);
  const result = isPlus ? sum : 2 * numbers[0] - sum;

  return (
    <div className='math-calc-form d-flex flex-column'>
      <div className='top-part d-flex flex-column'>
        {numbers.map((item, k) => {
          return (
            <React.Fragment key={k}>
              {k % 2 !== 0 && <span className={`devide-action action-1${k}`}>{symbol}</span>}
              <span className='amount'>{showUsd(item)}</span>
              {k % 2 !== 0 && <span className={`devide-action action-2${k}`}>{symbol}</span>}
            </React.Fragment>
          );
        })}
      </div>
      <span className='result-line' />
      <span className={`amount ${result > 0 ? 'green' : result < 0 ? 'red' : ''}`}>
        {showUsd(result)}
      </span>
    </div>
  );
};

const CalcModal = ({ close, initialForm, updateInitialForm, matchStarted }) => {
  const [form, setForm] = useState(setFromInitialForm());

  const onChangeForm = (name, amount) => {
    setForm({ ...form, [name]: amount });
  };

  function setFromInitialForm() {
    const { bucket_mgmt, sheet_price } = initialForm;
    const { season, weekly } = bucket_mgmt;
    return {
      sheet_price_preseason: sheet_price.preseason || 0,
      sheet_price_inseason: sheet_price.inseason || 0,
      forecast_preseason: Number((bucket_mgmt.total / sheet_price.preseason).toFixed()),
      forecast_inseason: 0,
      administrative_amount: bucket_mgmt.administrative_amount || 0,
      weekly_amount: weekly.amount || 0,
      weekly_first_place: weekly.first_place || 0,
      weekly_second_place_amount: weekly.second_place.amount || 0,
      weekly_second_place_is_valid: weekly.second_place.is_valid || 0,
      season_amount: season.amount || 0,
      season_first_place: season.first_place || 0,
      season_second_place_amount: season.second_place.amount || 0,
      season_second_place_is_valid: season.second_place.is_valid || 0,
      season_third_place_amount: season.third_place.amount || 0,
      season_third_place_is_valid: season.third_place.is_valid || 0,
      season_last_place_is_valid: season.last_place.is_valid || 0,
    };
  }

  const onApplyCalcs = () => {
    updateInitialForm({
      bucket_mgmt: {
        total: total,
        administrative_amount: Number(form.administrative_amount),
        weekly: {
          amount: form.weekly_amount,
          first_place: form.weekly_first_place,
          second_place: {
            amount: form.weekly_second_place_amount,
            is_valid: form.weekly_second_place_is_valid,
          },
        },
        season: {
          amount: form.season_amount,
          first_place: form.season_first_place,
          second_place: {
            amount: form.season_second_place_amount,
            is_valid: form.season_second_place_is_valid,
          },
          third_place: {
            amount: form.season_third_place_amount,
            is_valid: form.season_third_place_is_valid,
          },
          last_place: {
            is_valid: form.season_last_place_is_valid,
          },
        },
      },
      sheet_price: {
        preseason: form.sheet_price_preseason,
        inseason: form.sheet_price_inseason,
      },
    });
    close();
  };

  const total =
    form.sheet_price_preseason * form.forecast_preseason +
    form.sheet_price_inseason * form.forecast_inseason;

  return (
    <div className='modal-box financial-page calc-modal d-flex flex-column'>
      <div className='m-header'>
        <button onClick={close} className='btn p-0 m-close'>
          <IconClose color='#909090' />
        </button>
      </div>
      <div className='m-content flex-fill'>
        <div className='d-flex h-100'>
          <div className='input-blocks flex-fill'>
            <div className='w-block'>
              <div className='mb-2'>
                <p className='m-0 block-title'>Sheet price</p>
                <div className='d-flex'>
                  <AmountToggle
                    name='Preseason'
                    value={form.sheet_price_preseason}
                    onValueChange={onChangeForm.bind(null, 'sheet_price_preseason')}
                    noCheckArea
                    nameClassName='pl-0'
                    disabled={matchStarted}
                  />
                  <AmountToggle
                    name='In-season'
                    value={form.sheet_price_inseason}
                    onValueChange={onChangeForm.bind(null, 'sheet_price_inseason')}
                    noCheckArea
                    nameClassName='pl-4 pr-2'
                    disabled={matchStarted}
                  />
                </div>
              </div>
              <div>
                <p className='m-0 block-title'>Sales forecast</p>
                <div className='d-flex'>
                  <AmountToggle
                    name='Preseason'
                    value={form.forecast_preseason}
                    onValueChange={onChangeForm.bind(null, 'forecast_preseason')}
                    noCheckArea
                    nameClassName='pl-0'
                    inputSymbol='N'
                  />
                  <AmountToggle
                    name='In-season'
                    value={form.forecast_inseason}
                    onValueChange={onChangeForm.bind(null, 'forecast_inseason')}
                    noCheckArea
                    nameClassName='pl-4 pr-2'
                    inputSymbol='N'
                  />
                </div>
              </div>
            </div>
            <div className='w-block text-center total-info'>Forecast Budget: {showUsd(total)}</div>
            <div className='w-block d-flex align-items-center'>
              <p className='m-0 block-title'>Administrative</p>
              <AmountSwitcher
                className='justify-content-between w-100'
                total={total}
                value={form.administrative_amount}
                onValueChange={onChangeForm.bind(null, 'administrative_amount')}
              />
            </div>
            <div className='w-block d-flex'>
              <p className='m-0 block-title'>Weekly</p>
              <div className='w-100'>
                <AmountSwitcher
                  className='mb-2 justify-content-between'
                  total={total}
                  value={form.weekly_amount}
                  onValueChange={onChangeForm.bind(null, 'weekly_amount')}
                />
                <AmountToggle
                  name='First place'
                  className='pt-2 mb-20 w-100 justify-content-between'
                  value={form.weekly_first_place}
                  onValueChange={onChangeForm.bind(null, 'weekly_first_place')}
                  disabled={matchStarted}
                />
                <AmountToggle
                  name='Second place'
                  className='w-100 justify-content-between'
                  value={form.weekly_second_place_amount}
                  onValueChange={onChangeForm.bind(null, 'weekly_second_place_amount')}
                  toggle={form.weekly_second_place_is_valid}
                  onToggleChange={onChangeForm.bind(null, 'weekly_second_place_is_valid')}
                  disabled={matchStarted}
                />
              </div>
            </div>
            <div className='w-block d-flex'>
              <p className='m-0 block-title'>Season</p>
              <div className='w-100'>
                <AmountSwitcher
                  total={total}
                  value={form.season_amount}
                  onValueChange={onChangeForm.bind(null, 'season_amount')}
                  className='mb-2 justify-content-between'
                />
                <AmountToggle
                  name='First place'
                  className='pt-2 mb-20 w-100 justify-content-between'
                  value={form.season_first_place}
                  onValueChange={onChangeForm.bind(null, 'season_first_place')}
                />
                <AmountToggle
                  name='Second place'
                  className='mb-20 w-100 justify-content-between'
                  value={form.season_second_place_amount}
                  onValueChange={onChangeForm.bind(null, 'season_second_place_amount')}
                  toggle={form.season_second_place_is_valid}
                  onToggleChange={onChangeForm.bind(null, 'season_second_place_is_valid')}
                />
                <AmountToggle
                  name='Third place'
                  className='mb-20 w-100 justify-content-between'
                  value={form.season_third_place_amount}
                  onValueChange={onChangeForm.bind(null, 'season_third_place_amount')}
                  toggle={form.season_third_place_is_valid}
                  onToggleChange={onChangeForm.bind(null, 'season_third_place_is_valid')}
                />
                <AmountToggle
                  name='Last place'
                  className='w-100 justify-content-between'
                  value={form.sheet_price_preseason}
                  toggle={form.season_last_place_is_valid}
                  onToggleChange={onChangeForm.bind(null, 'season_last_place_is_valid')}
                />
              </div>
            </div>
          </div>
          <div className='calc-info-block ml-1'>
            <div className='w-block budget-calc'>
              <p className='m-0 block-title mb-2'>Budget Calc</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <div className='mb-1'>
                    Pre-Season
                    <span>
                      {form.sheet_price_preseason} * {form.forecast_preseason}
                    </span>
                  </div>
                  <div className='mb-1'>
                    In-Season
                    <span className='pl-2'>
                      {form.sheet_price_inseason} * {form.forecast_inseason}
                    </span>
                  </div>
                  <div className='mb-1'>Forecast Budget</div>
                </div>
                <div>
                  <MathCalcForm
                    symbol='+'
                    isPlus={true}
                    numbers={[
                      form.sheet_price_preseason * form.forecast_preseason,
                      form.sheet_price_inseason * form.forecast_inseason,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className='w-block'>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Sheet Revenue:</span>
                <span>{showUsd(form.administrative_amount)}</span>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <span>Forecast Balance:</span>
                <span>{showUsd(form.administrative_amount - total)}</span>
              </div>
            </div>
            <div className='w-block budget-calc'>
              <p className='m-0 block-title mb-1'>Week Calc</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <div className='mb-1'>
                    Bucket
                    <span className='pl-6'>
                      {form.weekly_amount} / {TOTAL_WEEKS}
                    </span>
                  </div>
                  <div className='mb-1'>1st Place</div>
                  <div className='mb-1'>2nd Place</div>
                  <div className='mb-1'>Total</div>
                </div>
                <div>
                  <MathCalcForm
                    symbol='–'
                    isPlus={false}
                    numbers={[
                      form.weekly_amount / TOTAL_WEEKS,
                      form.weekly_first_place,
                      form.weekly_second_place_is_valid ? form.weekly_second_place_amount : 0,
                    ]}
                  />
                </div>
              </div>
              <p className='m-0 block-title mb-1'>Season Calc</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <div className='mb-1'>Season Bucket</div>
                  <div className='mb-1'>1st Place</div>
                  <div className='mb-1'>2nd Place</div>
                  <div className='mb-1'>3rd Place</div>
                  <div className='mb-1'>Last Place</div>
                  <div className='mb-1'>Total</div>
                </div>
                <div>
                  <MathCalcForm
                    symbol='–'
                    isPlus={false}
                    numbers={[
                      form.season_amount,
                      form.season_first_place,
                      form.season_second_place_is_valid ? form.season_second_place_amount : 0,
                      form.season_third_place_is_valid ? form.season_third_place_amount : 0,
                      form.season_last_place_is_valid ? form.sheet_price_preseason : 0,
                    ]}
                  />
                </div>
              </div>
              <p className='m-0 block-title mb-1'>Budget Calc</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <div className='mb-1'>Forecast Budget</div>
                  <div className='mb-1'>Fundraiser Goal</div>
                  <div className='mb-1'>Weekly Bucket</div>
                  <div className='mb-1'>Season Bucket</div>
                  <div className='mb-1'>Total</div>
                </div>
                <div>
                  <MathCalcForm
                    symbol='–'
                    isPlus={false}
                    numbers={[
                      total,
                      form.administrative_amount,
                      form.weekly_amount,
                      form.season_amount,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='m-footer mt-1 d-flex justify-content-between'>
        <button className='btn btn-gray' onClick={() => setForm(setFromInitialForm())}>
          Reset
        </button>
        <button className='btn btn-primary' onClick={onApplyCalcs}>
          Apply to Pool
        </button>
      </div>
    </div>
  );
};

export default CalcModal;
