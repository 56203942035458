import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import SideBar from './components/SideBar/index';

import Home from './routes/Home';
import Knockout from './routes/Knockout';
import Pools from './routes/Pools';
import PoolsQP3New from './routes/PoolsQP3New';
import PooQP3View from './routes/PooQP3View';
import Reports from './routes/Reports';
import Schedule from './routes/Schedule';
import Sheets from './routes/Sheets';
import SheetView from './routes/SheetView';
import UserNew from './routes/UserNew';
import Users from './routes/Users';
import UserView from './routes/UserView';
import PoolsNewSelection from './routes/PoolsNewSelection';
import PoolsSurvivalNew from './routes/PoolsSurvivalNew';
import PooSurvivalView from './routes/PooSurvivalView';
import UserEntriesView from './routes/UserEntriesView';
import PooSurvivalStatsView from './routes/PooSurvivalStatsView';
import Fundraisings from './routes/Fundraisings';
import FundraisingNew from './routes/FundraisingNew';
import FundraisingView from './routes/FundraisingView';

const Main = () => {
  return (
    <div className='container-fluid p-0'>
      <div>
        <nav className='d-flex flex-column justify-content-between p-0 sidebar'>
          <SideBar />
        </nav>
        <main role='main' className='p-0 min-vh-100'>
          <Switch>
            <Route path='/home' component={Home} />
            <Route path='/fundraiser/new' component={FundraisingNew} />
            <Route path='/fundraiser/:id/view' component={FundraisingView} />
            <Route path='/fundraiser/:id/edit' component={FundraisingNew} />
            <Route path='/fundraiser' component={Fundraisings} />
            <Route path='/pools/qp3/:id/edit' component={PoolsQP3New} />
            <Route path='/pools/qp3/:id/view' component={PooQP3View} />
            <Route path='/pools/qp3/new' component={PoolsQP3New} />
            <Route path='/pools/survival/:id/edit' component={PoolsSurvivalNew} />
            <Route path='/pools/survival/new' component={PoolsSurvivalNew} />
            <Route path='/pools/survival/:id/view' component={PooSurvivalView} />
            <Route path='/pools/survival/:id/stats' component={PooSurvivalStatsView} />
            <Route path='/pools/new' component={PoolsNewSelection} />
            <Route path='/pools/:type' component={Pools} />
            <Route path='/sheets/:id/view' component={SheetView} />
            <Route path='/sheets' component={Sheets} />
            <Route path='/users/new' component={UserNew} />
            <Route path='/users/:id/view' component={UserView} />
            <Route path='/users/:id/sheet/:sheetId/view' component={SheetView} />
            <Route path='/users/:id/entries/:poolId/view' component={UserEntriesView} />
            <Route path='/users' component={Users} />
            <Route path='/schedule' component={Schedule} />
            <Route path='/reports' component={Reports} />
            <Route path='/knockout' component={Knockout} />
            <Redirect from='*' to={'/fundraiser'} />
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default Main;
