import React from 'react';
import { Link } from 'react-router-dom';
import { IconEdit, IconManage } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import { showUsd } from 'utils/appHelpers';

const headers = [
  {
    name: 'Fundraiser Name',
    className: 'pl-3 col-2',
    key: 'poolname',
  },
  {
    name: 'Season',
    className: 'col',
    key: 'season',
  },
  {
    name: 'Fundraiser Type',
    className: 'col',
    key: '',
  },
  {
    name: 'Sheets Sold',
    className: 'col',
    key: 'sheets_sold',
  },
  {
    name: 'Total Collected',
    className: 'col',
    key: 'total_collected',
  },
  {
    name: 'Season Payouts',
    className: 'col',
    key: 'season_prize',
  },
  {
    name: 'Weekly Payout',
    className: 'col',
    key: 'weekly_prize',
  },
  {
    name: '',
    className: 'col-2',
  },
];

const ListsQp3 = ({ list, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, i) => (
            <li key={i} className='d-flex list-item h-not-set'>
              <div className='pl-3 d-flex col-2 align-items-center'>{item.poolname}</div>
              <div className='col d-flex align-items-center'>{item.season}</div>
              <div className='col d-flex align-items-center'>QP3</div>
              <div className={`col d-flex align-items-center text-nowrap`}>{item.sheets_sold}</div>
              <div className='col d-flex align-items-center'>{showUsd(item.total_collected)}</div>
              <div className='col d-flex align-items-center'>{showUsd(item.season_prize)}</div>
              <div className='col d-flex align-items-center'>{showUsd(item.weekly_prize)}</div>
              <div className='col-2 p-2 justify-content-end flex-fill d-flex'>
                <Link
                  className='view-btn mr-2'
                  title='View/Manage'
                  to={`/pools/qp3/${item.pool_id}/view`}
                >
                  <IconManage />
                </Link>
                <Link className='view-btn mr-2' title='Edit' to={`/pools/qp3/${item.pool_id}/edit`}>
                  <IconEdit />
                </Link>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ListsQp3;
