import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';

const PoolCompleteStep = ({ onPoolSubmit, fetching }) => {
  return (
    <div className='d-flex flex-column lesson-complete-step text-center'>
      <h4>Fundraiser Setup is Complete</h4>
      <p>
        If you are sure about completeness of Fundraiser information then press Save, if not press{' '}
        <br /> Back to change any of configurations.
      </p>
      <div>
        <button className='btn min-100' type='button' onClick={onPoolSubmit} disabled={fetching}>
          {fetching ? <ButtonLoading /> : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default PoolCompleteStep;
