import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import CreateFormSteps from 'shared/components/CreateFormSteps';
import PoolGeneralStep from './components/PoolGeneralStep';
import PoolCompleteStep from './components/PoolCompleteStep';
import PoolFinancialStep from './components/PoolFinancialStep';
import PoolSettingsStep from './components/PoolSettingsStep';

const PoolsSurvivalNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [matchStarted, setMatchStarted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    activeCases: [],
    franchises: [],
    tags: [],
  });

  const [form, setForm] = useState({
    name: '',
    game: 'NFL',
    season: 2024,
    entrance_price: 100,
    teams_multi_pick: false,
    random_pick: false,
    allow_share_prize: false,
    type: 'survival',
    buy_back_settings: {
      available: false,
      price: 50,
      week: 1,
    },
    multi_tier_settings: {
      available: true,
      multi_tier_users: 2,
    },
    bucket_mgmt: {
      sales_forecast: 0,
      total: 0,
      administrative_amount: 0,
      season: {
        amount: 0,
      },
      undefeated: 100,
      tier: [0, 0],
      earliest_out: 1,
    },
  });

  const steps = [PoolGeneralStep, PoolSettingsStep, PoolFinancialStep, PoolCompleteStep];

  const constructFormData = form => {
    form.type = 'survival';
    if (!form.bucket_mgmt.total) {
      form.bucket_mgmt.total = form.entrance_price * form.bucket_mgmt.sales_forecast;
    }
    if (form.bucket_mgmt.tier?.length !== Number(form.multi_tier_settings.multi_tier_users)) {
      form.bucket_mgmt.tier = form.bucket_mgmt.tier.splice(
        0,
        Number(form.multi_tier_settings.multi_tier_users),
      );
    }
    return form;
  };

  const onPoolSubmit = async () => {
    try {
      setFetching(true);
      const body = constructFormData(form);
      const endpoint = id ? '/pool/update' : '/pool/create';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar(`Fundraiser successfully ${id ? 'updated' : 'created'}`, {
        variant: 'success',
      });
      history.push('/pools/survival');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const getPoolData = async id => {
    try {
      setFetching(true);
      const { data } = await Api.get(`/pool/${id}?type=survival`);
      setForm({ ...data.pool_config, poolId: id });
      setMatchStarted(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/courses/all');
    } finally {
      setFetching(false);
    }
  };

  const isDisableNext = form => {
    if (activeStep === 0) {
      return !form.name;
    }
    return false;
  };

  useEffect(() => {
    if (id) getPoolData(id);
    //eslint-disable-next-line
  }, [id]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && fetching) return <Loading className='mt-5 pt-5' />;

  return (
    <div className='pool-creation is-survival'>
      <Header isEditing={!!id} />
      <CreateFormSteps steps={steps} activeStep={activeStep} />
      <div className='py-4 px-4 mt-5 has-header'>
        <form
          className='d-flex flex-column justify-content-between creation-form-content'
          onSubmit={handleStepChange}
        >
          <div className='col-12'>
            <Step
              matchStarted={matchStarted}
              form={form}
              setForm={setForm}
              data={data}
              setData={setData}
              onPoolSubmit={onPoolSubmit}
              fetching={fetching}
              isEditing={!!id}
            />
          </div>
          <FooterButtons
            fetching={fetching}
            onGoBackStep={onGoBackStep}
            activeStep={activeStep}
            isLastStep={isLastStep}
            disableNext={isDisableNext(form)}
          />
        </form>
      </div>
    </div>
  );
};

export default PoolsSurvivalNew;
