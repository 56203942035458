/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError, showUsd } from 'utils/appHelpers';
import { IconEdit, IconReport, IconSchedule } from 'shared/components/Icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Loading from 'shared/components/Loading';
import fundraisingImg from 'assets/fundraising.png';
import PoolTable from './components/PoolTable';
import ReactModal from 'react-modal';
import SellSheetsModal from './components/SellSheetsModal';
import ReportExportModal from './components/ReportExportModal';
import SeasonStandingModal from './components/SeasonStandingModal';
import linkIcon from 'assets/linkIcon.png';
import copyIcon from 'assets/copyIcon.png';

const showPos = pos => {
  const text = pos === 1 ? 'st' : pos === 2 ? 'nd' : 'rd';
  return `${pos}${text}`;
};

const weeklyCongifs = [
  {
    value: item => (
      <span>
        Week {item.week} <span className='ml-5'>{showPos(item.pos)}</span>
      </span>
    ),
  },
  { value: item => item.winner },
  { value: item => `${item.points}` },
];

const seasonCongifs = [
  { value: i => i.name },
  { value: i => i.person },
  { value: i => `${i.value}` },
];

const poolCongifs = [{ value: i => i.name }, { value: i => i.value }];
const seasonPrizesCongifs = [
  { value: i => i.name },
  { value: i => (i.value ? `${showUsd(i.value)}` : '-') },
];

const seasonPrizesTotalCongifs = [{ value: i => i.name }, { value: i => i.value }];

const constructPoolStats = obj => {
  return Object.keys(obj).map(key => {
    key = key === 'admin_fee' ? 'fundraising_goal' : key;
    const name = key.replace('_', ' ');
    const value = key === 'sold_sheets' ? obj[key] : showUsd(obj[key]);
    return { name, value };
  });
};

const constructSeason = obj => {
  const { first_place, second_place, third_place, last_place } = obj;

  const genRow = (name, arr) => {
    return arr.map(pl => ({
      name,
      person: pl.winner,
      value: pl.points,
    }));
  };

  return [
    ...genRow('Season 1st Place', first_place),
    ...genRow('Season 2nd Place', second_place),
    ...genRow('Season 3rd Place', third_place),
    ...genRow('Season Last Place', last_place),
  ];
};

const constructSeasonPrizes = obj => {
  const {
    season_first_prize,
    // season_second_prize,
    // season_third_prize,
    weekly_prize,
    // season_last_prize,
  } = obj;
  return [
    { name: 'Weekly Payout', value: weekly_prize },
    { name: 'Season 1st Prize', value: season_first_prize },
    // { name: 'Season 2nd Prize', value: season_second_prize },
    // { name: 'Season 3rd Prize', value: season_third_prize },
    // { name: 'Season Last Prize', value: season_last_prize },
  ];
};

const constructSeasonPrizesTotal = obj => {
  const { pool_config } = obj;
  const { loss, tie, wins } = pool_config.weekly_winner_settings;
  const methods = `${wins ? 'Win' : ''} ${tie ? 'Tie' : ''}  ${loss ? 'Loss' : ''}`;
  return [
    { name: 'Weekly Calculation Method', value: methods },
    { name: 'Season Calculation Basis', value: pool_config.season_winner_settings },
    { name: 'Sheet Price - Preseason', value: `${showUsd(pool_config.sheet_price.preseason)}` },
    { name: 'Sheet Price - In-season', value: `${showUsd(pool_config.sheet_price.inseason)}` },
  ];
};

const FundraisingView = ({ match }) => {
  const [modalStanding, setModalStanding] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [reportModalState, setReportModalState] = useState(false);
  const history = useHistory();
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [users, setUsers] = useState([]);

  const getPoolData = async id => {
    try {
      const { data } = await Api.get(`/pool/${id}?type=fundraising`);
      data.season_prizes_total = constructSeasonPrizesTotal(data);
      data.pool_stats = constructPoolStats(data.pool_stats);
      data.season_winners = constructSeason(data.season_winners);
      data.season_prizes = constructSeasonPrizes(data.season_prizes);
      setData(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await Api.get(`/users/player-list`);
      setUsers(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getPoolPath = path => {
    const adminPath = window.location.origin;
    const userPath = adminPath.replace('admin.', '');
    return `${userPath}/home?path=${path}`;
  };

  const onOpenPoolUrl = e => {
    e?.preventDefault();
    window.open(getPoolPath(data.pool_config.url_path), '_blank');
  };

  const onCopyPoolUrl = e => {
    e?.preventDefault();
    navigator.clipboard.writeText(getPoolPath(data.pool_config.url_path));
    enqueueSnackbar('Fundraiser URL copied to clipboard', { variant: 'success' });
  };

  const onGoReports = e => {
    e?.preventDefault();
    history.push('/reports', { poolId: id });
  };

  useEffect(() => {
    if (id) getPoolData(id);
    //eslint-disable-next-line
    }, [id]);

  useEffect(() => {
    getUsers();
  }, []);

  if (!data) return <Loading className='has-header' />;

  return (
    <div className='pool-view-page is-survival'>
      <Header name={data.pool_config.name} />
      <div className='has-header pb-5'>
        <div className='pool-cover justify-content-between d-flex'>
          <div>
            <h3>{data.pool_config.name}</h3>
            <h4>Season Week {data.week > 18 ? 18 : data.week} / 18</h4>
            {data?.pool_config?.allow_postseason && (
              <h4>Play Off Week {data.week > 18 ? data.week - 18 : 0} / 4</h4>
            )}
            <button className='btn btn-primary d-block mt-6' onClick={() => setModalState(true)}>
              Sell Sheet
            </button>
            <div className='d-flex align-items-center'>
              <button
                onClick={onOpenPoolUrl}
                className='btn open-link-btn d-flex align-items-center justify-content-center'
              >
                <img src={linkIcon} alt='link icon' width={20} className='mr-2' /> Open
              </button>
              <button className='btn copy-link-btn' onClick={onCopyPoolUrl}>
                <img src={copyIcon} alt='copy icon' width={20} />
              </button>
            </div>
          </div>
          <div>
            <Link to={`/fundraiser/${id}/edit`} className='btn cover-btn'>
              <IconEdit color={'#fff'} />
            </Link>
            <br />
            <Link to='/schedule' className='btn cover-btn'>
              <IconSchedule color={'#fff'} />
            </Link>
            <br />
            <a href='#' onClick={onGoReports} className='btn cover-btn'>
              <IconReport color={'#fff'} />
            </a>
          </div>
          <div className='survival-pool-cover-title-img'>
            <img src={fundraisingImg} alt='pool' />
          </div>
        </div>
        <div className='pool-tables'>
          <div className='d-flex'>
            <PoolTable
              data={data.weekly_winners}
              title='Weekly Standings'
              className='is-green col-6 p-0'
              configs={weeklyCongifs}
              hasToggle
            />
            <PoolTable
              data={data.pool_stats}
              title='Fundraiser Stats'
              className='is-brown col-6 p-0'
              configs={poolCongifs}
            />
          </div>
          <div className='d-flex'>
            <PoolTable
              data={data.season_winners}
              title='Season Standings'
              className='is-green col-6 p-0'
              configs={seasonCongifs}
              onEyeClick={() => setModalStanding(true)}
              hasToggle
            />
            <PoolTable
              data={data.season_prizes}
              title='Payouts'
              className='is-brown brown-right col-2 p-0'
              itemsClassName='pr-2'
              configs={seasonPrizesCongifs}
            />
            <PoolTable
              data={data.season_prizes_total}
              title='Misc Info'
              className='is-brown col-4 p-0'
              configs={seasonPrizesTotalCongifs}
            />
          </div>
        </div>
        <ReactModal isOpen={modalState} className='custom-modal'>
          <SellSheetsModal poolId={id} users={users} close={() => setModalState(false)} />
        </ReactModal>
        <ReactModal isOpen={reportModalState} className='custom-modal'>
          <ReportExportModal poolId={id} close={() => setReportModalState(false)} />
        </ReactModal>
        <ReactModal isOpen={modalStanding} className='custom-modal'>
          <SeasonStandingModal poolId={id} close={() => setModalStanding(false)} />
        </ReactModal>
      </div>
    </div>
  );
};

export default FundraisingView;
