import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Select from 'react-select';
import { selectCustomStyles } from 'configs';
import { seasons, weeks } from 'app/Main/configs';
import { useSnackbar } from 'notistack';
import { confirmBox, getError } from 'utils/appHelpers';
import { Api, ApiPublic } from 'utils/connectors';
import { IconEdit, IconSave } from 'shared/components/Icons';
import moment from 'moment';
import { useSelector } from 'react-redux';

const constructDataWithDate = rows => {
  const result = {};
  rows.forEach(row => {
    const day = moment(row['Date']).format('dddd, MMMM DD');
    result[day] = result[day] || [];
    result[day].push(row);
  });
  return result;
};

const constructTeams = teams => {
  const result = {};
  teams.forEach(item => {
    result[item['Key']] = item;
  });
  return result;
};

const Schedule = () => {
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [updateFetch, setUpdateFetch] = useState(false);
  const [games, setGames] = useState();
  const [teams, setTeams] = useState();
  const [editScore, setEditScore] = useState();
  const [filter, setFilter] = useState({
    season: 2024,
    week: account.currentWeek,
  });
  const [form, setForm] = useState({
    homeScore: '',
    awayScore: '',
  });

  const activeWeek = weeks.find(item => item.id === filter.week);

  const onSelectWeek = option => {
    setFilter({ ...filter, week: option?.id });
  };

  const onSelectSeason = option => {
    setFilter({ ...filter, season: option?.id });
  };

  const onStartEditing = item => {
    const { GameKey, HomeScore, AwayScore } = item;
    setForm({ homeScore: HomeScore, awayScore: AwayScore });
    setEditScore(GameKey);
  };

  const getGames = async () => {
    try {
      setEditScore();
      const { data } = await ApiPublic.get(`/common/games`, { params: { week: filter.week } });
      const teamsData = await ApiPublic.get('/common/teams');
      setTeams(constructTeams(teamsData.data));
      setGames(constructDataWithDate(data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSubmitScore = async game => {
    try {
      setUpdateFetch(true);
      await Api.post('/sportdata/update-score', {
        season: game.Season,
        week: game.Week,
        awayTeam: game.AwayTeam,
        awayScore: form.awayScore,
        homeTeam: game.HomeTeam,
        homeScore: form.homeScore,
        hasStarted: game.HasStarted,
        isOver: game.IsOver,
        canceled: game.Canceled,
      });
      await getGames();
      setEditScore();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUpdateFetch(false);
    }
  };

  const onClickSave = game => {
    const modalOptions = {
      className: 'confirm-save',
      title: 'Confirmation of Changes',
      onSuccess: onSubmitScore.bind(null, game),
      onCancel: setEditScore,
      component: (
        <div>
          <h4 className='mb-2 text-primary weight-600'>Game result will be changed!</h4>
          <p>
            WARNING: These changes may impact the <br /> standings of all related fundraiser!.
            <br />
            <br />
            Are you sure you wish to proceed?
          </p>
        </div>
      ),
      cancel: 'No / Cancel',
      confirm: 'Yes / Apply',
    };
    confirmBox(modalOptions);
  };

  const onScoreChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    getGames();
  }, [filter]);

  return (
    <div>
      <Header />
      <div className='py-3 has-header'>
        <div className='d-flex mb-4'>
          <div className='form-field-group col-3'>
            <Select
              styles={selectCustomStyles}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => `${o.id}`}
              getOptionValue={o => o.id}
              placeholder={'Select Season'}
              onChange={onSelectSeason}
              value={{ id: filter.season }}
              options={seasons}
            />
          </div>
          <div className='form-field-group col-3'>
            <Select
              styles={selectCustomStyles}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => {
                if (account.currentWeek === o.id) return `${o.name} (current)`;
                return o.name;
              }}
              getOptionValue={o => o.id}
              placeholder={'Select Week'}
              onChange={onSelectWeek}
              options={weeks}
              value={activeWeek}
            />
          </div>
        </div>
        <div className='col-12 games-score-block'>
          {games &&
            Object.keys(games).map(date => {
              const rowGames = games[date];
              return (
                <div key={date}>
                  <h4>{date}</h4>
                  <div>
                    {rowGames.map((game, i) => {
                      const isEditing = editScore === game.GameKey;
                      const homeTeam = teams[game['HomeTeam']];
                      const awayTeam = teams[game['AwayTeam']];
                      return (
                        <div className='game-block mb-4 d-flex' key={i}>
                          <div className='time-block box text-center d-flex flex-column justify-content-end'>
                            {moment(game['Date']).format('h:mm')}
                            <span className='d-block'>{moment(game['Date']).format('a')}</span>
                          </div>
                          <div className='game-info box mx-2 d-flex justify-content-between align-items-center'>
                            <div className='col p-0 d-flex align-items-center'>
                              <img
                                height='30'
                                src={awayTeam && awayTeam['WikipediaLogoUrl']}
                                alt='imgl'
                              />
                              <p className='team-name ml-2 ml-sm-4 m-0 col-7 p-0'>
                                {awayTeam && awayTeam['FullName']}
                              </p>
                            </div>
                            {!isEditing && (
                              <div className='col-3 p-0 text-center team-score'>
                                {game.AwayScore} - {game.HomeScore}
                              </div>
                            )}
                            {isEditing && (
                              <div className='col-3 p-0 text-center team-score d-flex'>
                                <input
                                  type='number'
                                  name='awayScore'
                                  value={form.awayScore}
                                  onChange={onScoreChange}
                                />
                                -
                                <input
                                  type='number'
                                  name='homeScore'
                                  value={form.homeScore}
                                  onChange={onScoreChange}
                                />
                              </div>
                            )}
                            <div className='col justify-content-end p-0 d-flex align-items-center'>
                              <p className='team-name mr-2 mr-sm-4 m-0 text-right col-7 p-0'>
                                {homeTeam && homeTeam['FullName']}
                              </p>
                              <img
                                height='30'
                                src={homeTeam && homeTeam['WikipediaLogoUrl']}
                                alt='imgl'
                              />
                            </div>
                          </div>
                          <div className='edit-block box d-flex justify-content-center'>
                            {!isEditing && (
                              <button
                                title='Edit'
                                className='btn btn-non-shadow p-0'
                                onClick={onStartEditing.bind(null, game)}
                              >
                                <IconEdit width='32' height='29' />
                              </button>
                            )}
                            {isEditing && (
                              <button
                                title='Save'
                                disabled={updateFetch}
                                className='btn btn-primary btn-non-shadow p-0 btn-block'
                                onClick={onClickSave.bind(null, game)}
                              >
                                <IconSave color='#fff' width='32' height='32' />
                              </button>
                            )}
                          </div>
                          {game.updater && (
                            <div className='manual-update'>
                              <span>M</span>
                              <div className='hover-block'>
                                <p className='time'>
                                  {moment(game.UpdatedAtByAdmin).format('MMM DD, YYYY')}
                                </p>
                                <p>{game.updater}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
