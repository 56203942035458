import React from 'react';
import SwitcherButtons from 'shared/components/SwitcherButtons';
import EditingInput from 'shared/components/editingForms/EditingInput';

const onOffButtons = [{ id: false, name: 'Off' }, { id: true, name: 'On' }];

const PoolGeneralStep = ({ form, setForm, isEditing }) => {
  const handleChange = ({ target }) => {
    const { name, value } = target;
    const newForm = { ...form };
    newForm[name] = value;
    setForm(newForm);
  };

  const handleChangeEval = (name, value) => {
    try {
      const newForm = { ...form };
      // eslint-disable-next-line no-eval
      eval(`newForm.${name} = ${value}`);
      setForm(newForm);
    } catch (err) {}
  };

  return (
    <div className='px-2'>
      <h6 className='mb-3'>Fundraiser General Settings</h6>
      <div className='d-flex row'>
        <div className='col-12 user-creation-form'>
          <ul className='p-0 mb-0 col-5'>
            <li className='form-field-group'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Fundraiser Name*:</label>
              </div>
              <EditingInput
                type='text'
                name='name'
                value={form.name}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={true}
                maxLength='180'
              />
            </li>
          </ul>
          <div className='settings-item pl-3'>
            <div className='d-flex align-items-center mb-3'>
              <label className='mb-0 p-0 text-secondary weight-700 mr-5'>Include Playoff</label>
              <SwitcherButtons
                buttons={onOffButtons}
                activeItem={form.allow_postseason}
                onChange={value => handleChangeEval('allow_postseason', value)}
                disabled={isEditing}
              />
            </div>
            <p>
              If disabled pool lasts 18 weeks. If enabled the pool includes 4 additional weeks of
              playoff.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolGeneralStep;
