import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ poolId, user }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/users'>Players</Link>
        <span className='mx-2'>/</span>
        <Link to={`/users/${user.user_id}/view`}>
          {user.firstname} {user.lastname}
        </Link>
        <span className='mx-2'>/</span>
        <span>Fundraiser ID: {poolId}</span>
      </div>
    </div>
  );
};

export default Header;
