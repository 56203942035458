import {
  IconUsers,
  // IconHome,
  IconReports,
  IconSheet,
  // IconPool,
  IconSchedule,
  // IconKnockout,
  IconFundraising,
} from 'shared/components/Icons';

export const navigation = [
  // {
  //   name: 'Dashboard',
  //   path: '/home',
  //   icon: IconHome,
  // },
  {
    name: 'Fundraiser',
    path: '/fundraiser',
    icon: IconFundraising,
  },
  // {
  //   name: 'Pools',
  //   path: '/pools/survival',
  //   parentPath: '/pools',
  //   icon: IconPool,
  // },
  {
    name: 'Donor / Player',
    path: '/users',
    icon: IconUsers,
  },
  {
    name: 'Sheets',
    path: '/sheets',
    icon: IconSheet,
  },
  {
    name: 'Schedule',
    path: '/schedule',
    icon: IconSchedule,
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: IconReports,
  },
  // {
  //   name: 'Knockout',
  //   path: '/knockout',
  //   icon: IconKnockout,
  // },
];
